import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, from, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';

interface ClickstreamEvent {
  event_name: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private apiUrl = `${environment.baseApiUrl}/api/v1/jobs`;
  private isLoggedIn = false;

  constructor(private http: HttpClient, private storage: Storage) {
    this.storage.create()
      .then(() => console.log('Storage ready'))
      .catch(e => console.error('Error initializing storage', e));
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post<any>(`${environment.baseApiUrl}/api/v1/sessions`, { session: {email, password }}).pipe(
      map(response => {
        // Process and return the response data if successful
        const token = response.token;

        this.storeToken(token);
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        // Handling the error response
        const errorMessage = error.error?.error || error.message;
        // Rethrow the error with custom structure
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  isAuthenticated(): Observable<boolean> {
    return from(this.getToken()).pipe(
      switchMap(token => {
        if (!token) {
          return of(false); // If no token, return Observable of false
        }
        return this.http.get<any>(`${environment.baseApiUrl}/api/v1/sessions`, {
          headers: { 'Authorization': `Bearer ${token}` }
        }).pipe(
          map(response => response.session === true),
          catchError((error: HttpErrorResponse) => {
            // Handle specific error codes silently
            if (error.status === 404) {
              return of(false);
            }
            // Log error for other cases
            console.error('Error in isAuthenticated:', error);
            return of(false); // Return Observable of false in case of an error
          })
        );
      })
    );
  }

  getJobs(): Observable<any> {
    return this.http.get(this.apiUrl).pipe(
      map(response => {
        // Process and return the response data if successful
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        // Handling the error response
        const errorMessage = error.error?.error || error.message;
        // Rethrow the error with custom structure
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  getJob(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${id}`).pipe(
      map(response => {
        // Process and return the response data if successful
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        // Handling the error response
        const errorMessage = error.error?.error || error.message;
        // Rethrow the error with custom structure
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  getBlogs(): Observable<any> {
    return this.http.get(`${environment.baseApiUrl}/api/v1/blogs`).pipe(
      map(response => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error?.error || error.message;
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  getBlog(id: string): Observable<any> {
    return this.http.get(`${environment.baseApiUrl}/api/v1/blogs/${id}`).pipe(
      map(response => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error?.error || error.message;
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  register(email: string, password: string, name: string): Observable<any> {
    return this.http.post(`${environment.baseApiUrl}/api/v1/users`, { user: {email, password, name }}).pipe(
      map(response => {
        // Process and return the response data if successful
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        // Handling the error response
        const errorMessage = error.error?.error || error.message;
        // Rethrow the error with custom structure
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(`${environment.baseApiUrl}/api/v1/forgot_passwords`, { user: { email }}).pipe(
      map(response => {
        // Process and return the response data if successful
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        // Handling the error response
        const errorMessage = error.error?.error || error.message;
        // Rethrow the error with custom structure
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http.post(`${environment.baseApiUrl}/api/v1/reset-password`, { token, password }).pipe(
      map(response => {
        // Process and return the response data if successful
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        // Handling the error response
        const errorMessage = error.error?.error || error.message;
        // Rethrow the error with custom structure
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  logout(): Observable<any> {
    return this.http.post(`${environment.baseApiUrl}/api/v1/logout`, {}).pipe(
      map(response => {
        // Process and return the response data if successful
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        // Handling the error response
        const errorMessage = error.error?.error || error.message;
        // Rethrow the error with custom structure
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  getProfile(): Observable<any> {
    return this.http.get(`${environment.baseApiUrl}/api/v1/profiles`).pipe(
      map(response => {
        // Process and return the response data if successful
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        // Handling the error response
        const errorMessage = error.error?.error || error.message;
        // Rethrow the error with custom structure
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  updateProfile(name: string, email: string): Observable<any> {
    return this.http.put(`${environment.baseApiUrl}/api/v1/profile`, { name, email }).pipe(
      map(response => {
        // Process and return the response data if successful
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error?.error || error.message;
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  updatePassword(current_password: string, password: string, password_confirmation: string): Observable<any> {
    return this.http.put(`${environment.baseApiUrl}/api/v1/profile/password`, { current_password, password, password_confirmation }).pipe(
      map(response => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error?.error || error.message;
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  deleteAccount(): Observable<any> {
    return this.http.delete(`${environment.baseApiUrl}/api/v1/profile`).pipe(
      map(response => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error?.error || error.message;
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  applyJob(jobId: string): Observable<any> {
    return this.http.post(`${environment.baseApiUrl}/api/v1/jobs/${jobId}/apply`, {}).pipe(
      map(response => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error?.error || error.message;
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  getDashboard(): Observable<any> {
    return from(this.getToken()).pipe(
      switchMap(token => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return this.http.get(`${environment.baseApiUrl}/api/v1/dashboards`, { headers });
      }),
      map(response => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error?.error || error.message;
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  postResume(formData: any): Observable<any> {
    return from(this.getToken()).pipe(
      switchMap(token => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return this.http.post(`${environment.baseApiUrl}/api/v1/resumes`, { formData }, { headers });
      }),
      map(response => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error?.error || error.message;
        return throwError(() => ({ status: error.status, message: errorMessage }));
      })
    );
  }

  sendEvents(events: ClickstreamEvent[]): Observable<any> {
    return from(this.getToken()).pipe(
      switchMap(token => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return this.http.post(`${environment.baseApiUrl}/api/v1/events`, { events }, { headers });
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('Error sending events', error);
        return throwError(() => new Error(`Status: ${error.status}, Message: ${error.message}`));
      })
    );
  }

  

  async storeToken(token: string): Promise<void> {
    try {
      await this.storage.set('auth_token', token);
    } catch (error) {
      console.error('Error storing token', error);
    }
  }

  async getToken(): Promise<string> {
    return await this.storage.get('auth_token');
  }

  async getCustId(): Promise<string> {
    return await this.storage.get('cust_id');
  }
};
