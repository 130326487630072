import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Capacitor } from '@capacitor/core';


const isMobile = Capacitor.isNativePlatform();
console.log('isMobile', isMobile);

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule), pathMatch: 'full'},
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule) },
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule), canActivate: [AuthGuard] },
  { path: 'coming-soon', loadChildren: () => import('./pages/coming-soon/coming-soon.module').then( m => m.ComingSoonPageModule) },
  { path: 'resume-booster', loadChildren: () => import('./pages/resume-booster/resume-booster.module').then( m => m.ResumeBoosterPageModule) },
  { path: 'privacy-policy', loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule) },
  { path: 'signup', loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule) },
  { path: 'signup/:planType', loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule) },
  { path: 'welcome', loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule) },
  { path: 'forgot-password', loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule) },
  { path: 'verification', loadChildren: () => import('./pages/register/verification/verification.module').then( m => m.VerificationPageModule) },
  { path: 'careers', loadChildren: () => import('./pages/careers/careers.module').then( m => m.CareersPageModule) },
  { path: 'job-description', loadChildren: () => import('./pages/job-description/job-description.module').then( m => m.JobDescriptionPageModule) },
  { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule) },
  { path: 'blog', loadChildren: () => import('./pages/blog/blog.module').then( m => m.BlogPageModule) },
  { path: 'blog/:id', loadChildren: () => import('./pages/blog/blog-detail/blog-detail.module').then( m => m.BlogDetailPageModule) },
  { path: 'account-settings', loadChildren: () => import('./pages/account-settings/account-settings.module').then( m => m.AccountSettingsPageModule) },
  { path: 'contact-us', loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule) },
  { path: '**', redirectTo: '404'},
  { path: '404', loadChildren: () => import('./pages/page-not-found/page-not-found.module').then( m => m.PageNotFoundPageModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
