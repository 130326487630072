import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { Subject, filter } from 'rxjs';
import { bufferTime } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { TrackingService } from '../tracking/tracking.service';

@Injectable({
  providedIn: 'root'
})
export class ClickstreamService {
  private eventSubject: Subject<any> = new Subject<any>();
  private sessionStartTime: number;
  private lastActivityTime: number;

  constructor(
    private apiService: ApiService,
    private trackingService: TrackingService,
    private router: Router
    ) {
    this.setupEventBuffer();
    this.initSession();
    this.trackPageViews();
    console.log('ClickstreamService initialized');
  }

  private initSession(): void {
    const session_start_time = sessionStorage.getItem('session_start_time')
    if (session_start_time) {
      const sessionData = { session_start_time: session_start_time }
      this.trackEvent('restore-session', sessionData);
      // console.log('Restoring existing session');
    } else {
      const sessionStartTime = this.trackingService.getSessionId();
      const sessionData = { session_start_time: sessionStartTime }
      this.trackEvent('session-start', sessionData);
      // console.log('Initializing new session');
    }
  }

  endSession(): void {
    console.log('Ending session');
    const endTime = Date.now();
    const sessionDuration = endTime - this.sessionStartTime;
    const lastActivityDuration = endTime - this.lastActivityTime;

    // Create a session end event data
    const sessionEndData = {
      sessionDuration: sessionDuration,
      lastActivityDuration: lastActivityDuration,
      eventType: 'session-end' // Indicate that this is a session end event
    };

    // Use trackEvent to send the session end event
    this.trackEvent('session-end', sessionEndData);
  }

  // This method adds Visitor ID and timestamp to the event data
  trackEvent(eventType: string, eventData: any) {
    const visitorId = this.trackingService.getVisitorId();
    const timestamp = new Date().toISOString(); // ISO 8601 format timestamp

    this.eventSubject.next({
      event_type: eventType,
      data: eventData,
      visitor_id: visitorId,
      client_created_at: timestamp
    });
  }

  // Sets up a buffer to send events in batches every 5 seconds
  private setupEventBuffer() {
    console.log('Setting up event buffer');
    this.eventSubject.pipe(
      bufferTime(5000) // Adjust buffer time as needed
    ).subscribe(events => {
      if (events.length > 0) {
        this.apiService.sendEvents(events).subscribe({
          next: (response) => console.log('Data sent successfully', response),
          error: (error) => console.error('Error sending data', error)
        });
      }
    });
  }

  private trackPageViews() {
    this.router.events.pipe(
      filter((event: RouterEvent): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.trackEvent('page-view', {
        url: event.urlAfterRedirects
      });
    });
  }
}
