import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { ApiService } from '../services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private apiService: ApiService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.apiService.isAuthenticated().pipe(
      map(user => {
        if (user) {
          console.log('User is authenticated', user);
          console.log('Access granted to:', state.url);
          return true;
        } else {
          console.log('User is not authenticated', user);
          console.log('Access denied. Redirecting to /login from:', state.url);
          return this.router.createUrlTree(['/login']);
        }
      })
    );
  }
}
