import { Component, OnInit } from '@angular/core';
import { ClickstreamService } from './services/clickstream/clickstream.service';
import { Router, NavigationEnd } from '@angular/router';
import { TrackingService } from './services/tracking/tracking.service';
import { Platform } from '@ionic/angular';
import { DeviceService } from './services/device/device.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {
  private boundDocumentClick: (event: any) => void;

  constructor(
    private clickstreamService: ClickstreamService,
    private router: Router,
    private trackingService: TrackingService,
    private platform: Platform,
    private deviceService: DeviceService
    ) {
    this.boundDocumentClick = this.handleDocumentClick.bind(this);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Log navigation event
        console.log('Navigation Event:', event);
      }
    });
  }

  ngOnInit() {
    document.addEventListener('click', this.boundDocumentClick);
    // this.logSessionEndEvent();

    // this.platform.ready().then(() => {
    //   if (this.platform.is('desktop')) {
    //     this.router.navigate(['/']);
    //   } else {
    //     this.router.navigate(['/login']);
    //   }
    // });
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.boundDocumentClick);
    // this.logSessionEndEvent();
  }

  handleDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;

    // Send data to ClickstreamService
    const clickDetails = {
      type: 'click',
      tagName: targetElement.tagName,
      id: targetElement.id,
      class: targetElement.className,
      text: targetElement.innerText
    };
    this.clickstreamService.trackEvent('click', clickDetails);
  }

  // logSessionEndEvent(): void {
  //   window.addEventListener('beforeunload', (event) => {
  //     // event.preventDefault();
  //     const visitorId = this.trackingService.getVisitorId();
  //     const timestamp = new Date().toISOString(); // ISO 8601 format timestamp

  //     navigator.sendBeacon(
  //       `${environment.baseApiUrl}/api/v1/events`,
  //       JSON.stringify(
  //         {
  //           events:{
  //             event_type: 'session-end',
  //             data: '',
  //             visitor_id: visitorId,
  //             client_created_at: timestamp
  //           }
  //         }
  //       )
  //     );
  //   });
  // }
}
