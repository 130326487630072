import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private readonly storageKey = 'unique_visitor_id';
  private readonly sessionKey = 'session_start_time';

  getVisitorId(): string {
    let visitorId = localStorage.getItem(this.storageKey);
    if (!visitorId) {
      visitorId = uuidv4();
      localStorage.setItem(this.storageKey, visitorId);
    }
    return visitorId;
  }

  getSessionId(): number {
    let sessionStartTime = sessionStorage.getItem(this.sessionKey);

    if (!sessionStartTime) {
      sessionStartTime = Date.now().toString();
      sessionStorage.setItem(this.sessionKey, sessionStartTime);
    }

    return parseInt(sessionStartTime, 10);
  }
}
