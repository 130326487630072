import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  public isMobile: boolean;

  constructor() {
    this.isMobile = Capacitor.isNativePlatform();
  }
}
